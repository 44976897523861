import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import { Container, Heading, Content } from "react-bulma-components";
//import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Container>
    <Heading>NOT FOUND</Heading>
    <Content>Nothing to see here go to: <Link to="/"> Greenheat heat recovery for GPU mining rigs </Link>
    </Content>
    </Container>
  </Layout>
)

export default NotFoundPage
